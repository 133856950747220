import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { navigate } from "@reach/router"
import "bootstrap/dist/css/bootstrap.min.css"
import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import themeWW from "../styles/theme-ww"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import Dropdown from "react-bootstrap/Dropdown"
import Nudge from "../components/nudge";

// First Section
const FirstSectionBackground = styled.div`
  background-color: ${props => props.backgroundColor};
`

// Header
const MenuBrand = styled(Navbar.Brand)`
  color: ${props => props.color} !important;
  font: 32px/58px Abril Fatface;
`
const MenuItem = styled(Navbar.Text)`
  margin-right: 21px;
  font: 16px/22px Roboto;
  color: ${props => props.color} !important;
  &:hover {
    color: ${props => props.theme.colors.accent_yellow} !important;
  }
`
const ButtonHeader = styled(Button)`
  height: 41px;
  font: 16px/22px Roboto;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border-color: ${props => props.borderColor};
  transition: 0.25s ease-out;
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: ${props => props.colorHover} !important;
    background-color: ${props => props.backgroundColorHover} !important;
    border-color: ${props => props.borderColorHover} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    margin-top: -3px;
    margin-bottom: 3px;
  }
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: ${props => props.colorPressed} !important;
    background-color: ${props => props.backgroundColorPressed} !important;
    border-color: ${props => props.theme.borderColorPressed} !important;
    box-shadow: none !important;
    margin-top: -3px;
    margin-bottom: 3px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`

// To Push Footer to the bottom of the page
const Wrap = styled.div`
  margin: 0 auto -274px;
  min-height: 100%;
  height: auto !important;
  height: 100%;
`

const Push = styled.div`
  height: 274px;
`

const Footer = styled.div`
  min-height: 274px;
  background-color: ${props => props.theme.colors.primary_violet_dark};
  color: ${props => props.theme.colors.white_dark};
`

// footer
const FooterBrand = styled.div`
  margin-top: 40px;
  font: 32px/58px Abril Fatface;
`
const TextFooterCol1 = styled.div`
  font: 16px/28px Roboto;
  a {
    color: ${props => props.theme.colors.white_dark};
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colors.accent_yellow} !important;
    }
  }
`
const TextFooterCol1Margin = styled(TextFooterCol1)`
  margin-top: 45px;
  @media (max-width: 991px) {
    margin-top: 0;
  }
`
const TextFooterCol2 = styled.div`
  font: 16px/22px Roboto;
  margin-top: 16px;
  a {
    color: ${props => props.theme.colors.white_dark};
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colors.accent_yellow} !important;
    }
  }
`
const TextFooterCol2Margin = styled(TextFooterCol2)`
  margin-top: 50px;
  @media (max-width: 767px) {
    margin-top: 25px;
  }
`
const TextFooterCol2MarginBottom = styled(TextFooterCol2)`
  margin-bottom: 50px;
  @media (max-width: 767px) {
    margin-bottom: 25px;
  }
`

const TextFooterCol3Margin = styled.div`
  margin-top: 50px;
  font: 16px/20px Roboto;
  @media (max-width: 767px) {
    margin-top: 25px;
  }
`
const FASocmedButton = styled(FontAwesomeIcon)`
  font-size: 3rem;
  color: ${props => props.theme.colors.accent_yellow};
  transition: 0.25s ease-out;
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: ${props => props.theme.colors.accent_yellow_hover};
    margin-top: -3px;
    cursor: pointer;
  }
`

const SocmedRow = styled(Row)`
  margin-top: 10px;
  margin-bottom: 40px;
`
const FormControlSubmit = styled(Form.Control)`
  width: 250px;
  margin-top: 10px;
  @media (max-width: 991px) {
    width: 100%;
  }
`
const ButtonSubmit = styled(Button)`
  margin-top: 10px;
  color: ${props =>
    props.theme.buttons.primary_button_yellow_violet.normal.color};
  background-color: ${props =>
    props.theme.buttons.primary_button_yellow_violet.normal.background_color};
  border-color: ${props =>
    props.theme.buttons.primary_button_yellow_violet.normal.border_color};
  transition: 0.25s ease-out;
  &:hover {
    margin-top: 7px;
    color: ${props =>
      props.theme.buttons.primary_button_yellow_violet.hovered
        .color} !important;
    background-color: ${props =>
      props.theme.buttons.primary_button_yellow_violet.hovered
        .background_color} !important;
    border-color: ${props =>
      props.theme.buttons.primary_button_yellow_violet.hovered
        .border_color} !important;
    box-shadow: 0px 3px 20px
      ${props =>
        props.theme.buttons.primary_button_yellow_violet.hovered
          .shadow} !important;
  }
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: ${props =>
      props.theme.buttons.primary_button_yellow_violet.pressed
        .color} !important;
    background-color: ${props =>
      props.theme.buttons.primary_button_yellow_violet.pressed
        .background_color} !important;
    border-color: ${props =>
      props.theme.buttons.primary_button_yellow_violet.pressed
        .border_color} !important;
    box-shadow: none !important;
  }
`
const DropdownMenuProduct = styled(Dropdown.Toggle)`
  padding-left: 0;
  color: ${props => props.headerMenuColor} !important;
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: ${props => props.theme.colors.accent_yellow} !important;
  }
`

const MenuItemProduct = styled(MenuItem)`
  font: bold 16px/22px Roboto;
  display: flex;
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    background-color: transparent;
    color: ${props => props.theme.colors.primary_violet} !important;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: ${props => props.theme.colors.white};
    text-decoration: none !important;
    background-color: transparent;
  }
`

const GreenButton = styled.div`
  letter-spacing: 0;
  color: ${props => props.theme.colors.white};
  text-align: left;
  font: normal normal bold 12px/22px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  background: ${props => props.theme.colors.secondary_green} 0% 0% no-repeat padding-box;
  border-radius: 3px;
  width: fit-content;
  padding: 0 6px 0 6px;
  margin-left: 5px;
`
const StyledLink = styled(Link)`
  text-decoration: none !important;
`

export default function Layout(props) {
  const { handleSubmit, register } = useForm()
  const onSubmit = values => {
    async function subscribe() {
      const response = await fetch(
        "https://asia-southeast2-womenworks-app.cloudfunctions.net/emailSubscriptionHttpRequest-subscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: values.email,
            name: values.name,
          }),
        }
      )
      if (response.status && response.status === 200) {
        navigate("/thank-you/")
      } else {
        console.log("Error - response.status:", response.status)
      }
    }
    subscribe()
  }

  const NAV_KEY = {
    business: "business",
    mentors: "mentors",
    aboutUs: "aboutUs",
    programs: "programs",
  }

  const [showMentors, setShowMentors] = useState(false)
  const [showAboutUs, setShowAboutUs] = useState(false)
  const [showBusiness, setShowBusiness] = useState(false)
  const [showPrograms, setShowPrograms] = useState(false)
  const showDropdown = key => e => {
    if (key === NAV_KEY.mentors) {
      setShowMentors(!showMentors)
    } else if (key === NAV_KEY.aboutUs) {
      setShowAboutUs(!showAboutUs)
    } else if (key === NAV_KEY.business) {
      setShowBusiness(!showBusiness)
    } else if (key === NAV_KEY.programs) {
      setShowPrograms(!showPrograms)
    }
  }
  const hideDropdown = key => e => {
    if (key === NAV_KEY.mentors) {
      setShowMentors(false)
    } else if (key === NAV_KEY.aboutUs) {
      setShowAboutUs(false)
    } else if (key === NAV_KEY.business) {
      setShowBusiness(false)
    } else if (key === NAV_KEY.programs) {
      setShowPrograms(false)
    }
  }

  return (
    <ThemeProvider theme={themeWW}>
      <Global
        //To Push Footer to the bottom of the page
        styles={css`
          html,
          body,
          #___gatsby,
          #gatsby-focus-wrapper {
            height: 100%;
            font-family: "Roboto";
          }
          a.active span {
            @media (min-width: 1024px) {
              border-bottom: 3px solid;
              padding-bottom: 3px;
            }
          }
          .dropdown-menu {
            background-color: #fffff;
            border: transparent;
            border: none;
            box-shadow: 5px 5px 10px #00000029;
          }
          .dropdown-menu .dropdown-item > li > a:hover {
            background-image: none;

            background-color: #fffff;
          }

          .navbar {
            background: none;
          }

          .dropdown-menu a:hover {
            background-color: #fffff;
          }

          .btn-primary:not(:disabled):not(.disabled).active:focus,
          .btn-primary:not(:disabled):not(.disabled):active:focus,
          .show > .btn-primary.dropdown-toggle:focus {
            box-shadow: none;
          }

          .btn-primary:not(:disabled):not(.disabled).active,
          .btn-primary:not(:disabled):not(.disabled):active,
          .show > .btn-primary.dropdown-toggle {
            color: inherit;
            background-color: transparent !important;
            border-color: transparent !important;
          }

          .btn-primary {
            background-color: transparent;
            border-color: transparent;
          }

          .btn-primary.focus,
          .btn-primary:focus {
            background-color: transparent;
            border-color: transparent;
            box-shadow: none;
          }

          .btn:hover {
            background-color: transparent;
            border-color: transparent;
          }

          .dropdown-item.active,
          .dropdown-item:active {
            text-decoration: none;
            background-color: transparent;
          }
        `}
      />
      <Wrap>
        <FirstSectionBackground
          className="first-section-background"
          backgroundColor={props.firstSectionBGColor}
        >
          <Navbar expand="lg">
            <Container>
              <Link
                to={`/`}
                title={`Empowering the world with #bad-ass, #connected, and #financially-independent Indonesian women`}
              >
                <MenuBrand color={props.LogoColor}>WomenWorks</MenuBrand>
              </Link>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Dropdown
                    show={showMentors}
                    onMouseEnter={showDropdown(NAV_KEY.mentors)}
                    onMouseLeave={hideDropdown(NAV_KEY.mentors)}
                  >
                    <DropdownMenuProduct
                      headerMenuColor={props.HeaderMenuColor}
                    >
                      Our Mentors
                    </DropdownMenuProduct>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <StyledLink
                          to={`/the-ladybosses/`}
                          activeClassName="active"
                          title={`Get mentored and connected to Indonesia’s inspiring professionals through WomenWorks`}
                        >
                          <MenuItemProduct color="#4E4F58">
                            The LadyBosses
                          </MenuItemProduct>
                        </StyledLink>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <StyledLink
                          to={`/he-for-she/`}
                          activeClassName="active"
                          title={`A women-first connection and virtual knowledge sharing platform to build meaningful connections between men and women in order to grow and advance in their professional life. All men will undergo a tight vetting process by WomenWorks.`}
                        >
                          <MenuItemProduct color="#4E4F58">
                            Our Male Allies
                          </MenuItemProduct>
                        </StyledLink>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown
                    show={showPrograms}
                    onMouseEnter={showDropdown(NAV_KEY.programs)}
                    onMouseLeave={hideDropdown(NAV_KEY.programs)}
                  >
                    <DropdownMenuProduct
                      headerMenuColor={props.HeaderMenuColor}
                    >
                      Our Programs
                    </DropdownMenuProduct>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <StyledLink
                          to={`/subscription/`}
                          activeClassName="active"
                          title={``}
                        >
                          <MenuItemProduct color="#4E4F58">
                            Subscription
                            <GreenButton >POPULAR</GreenButton>
                          </MenuItemProduct>
                        </StyledLink>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <StyledLink
                          to={`/one-on-one-mentorship/`}
                          activeClassName="active"
                          title={`Virtual mentorship programs between mentees and WomenWorks mentors. Mentors are tailored to each mentee's request.`}
                        >
                          <MenuItemProduct color="#4E4F58">
                            1:1 Mentorship
                          </MenuItemProduct>
                        </StyledLink>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <StyledLink
                          to={`/masterclass/`}
                          activeClassName="active"
                          title={`A virtual online knowledge-sharing and workshop sessions, tackling professional and personal growth challenges faced by women. All sessions will be hosted by WomenWorks mentors.`}
                        >
                          <MenuItemProduct color="#4E4F58">
                            Masterclass
                          </MenuItemProduct>
                        </StyledLink>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown
                    show={showBusiness}
                    onMouseEnter={showDropdown(NAV_KEY.business)}
                    onMouseLeave={hideDropdown(NAV_KEY.business)}
                  >
                    <DropdownMenuProduct
                      headerMenuColor={props.HeaderMenuColor}
                    >
                      WW for Business
                    </DropdownMenuProduct>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <StyledLink
                          to={`/womenbiz-services/`}
                          activeClassName="active"
                          title={``}
                        >
                          <MenuItemProduct color="#4E4F58">
                            WomenBiz Services
                          </MenuItemProduct>
                        </StyledLink>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <StyledLink
                          to={`/book-a-speaker/`}
                          activeClassName="active"
                          title={``}
                        >
                          <MenuItemProduct color="#4E4F58">
                            Book a Speaker
                            <GreenButton >NEW!</GreenButton>
                          </MenuItemProduct>
                        </StyledLink>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown
                    show={showAboutUs}
                    onMouseEnter={showDropdown(NAV_KEY.aboutUs)}
                    onMouseLeave={hideDropdown(NAV_KEY.aboutUs)}
                  >
                    <DropdownMenuProduct
                      headerMenuColor={props.HeaderMenuColor}
                    >
                      About Us
                    </DropdownMenuProduct>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <StyledLink
                          to={`/contact-us/`}
                          activeClassName="active"
                          title={``}
                        >
                          <MenuItemProduct color="#4E4F58">Contact Us</MenuItemProduct>
                        </StyledLink>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <StyledLink
                          to={`/faq/`}
                          activeClassName="active"
                          title={`Frequently asked questions for mentorship and WomenWork's service`}
                        >
                          <MenuItemProduct color="#4E4F58">FAQ</MenuItemProduct>
                        </StyledLink>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <StyledLink
                          to={`/who-we-are/`}
                          activeClassName="active"
                          title={`WomenWorks is the place for women to connect, empower, and reach their fullest potential. We are bad-ass. Connected. Financially independent.`}
                        >
                          <MenuItemProduct color="#4E4F58">
                            Our Team
                          </MenuItemProduct>
                        </StyledLink>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Link
                    to={`/app/`}
                    activeClassName="active"
                    title={`Sign up for WomenWorks personalised 1-on-1 mentorship / coaching and access to mentors’ professional network to help through every stage of your professional life.`}
                  >
                    <ButtonHeader
                      variant="outline-light"
                      color={props.HeaderButtonColor}
                      backgroundColor={props.HeaderButtonBackground}
                      borderColor={props.HeaderButtonBorder}
                      colorHover={props.HeaderButtonColorHover}
                      backgroundColorHover={props.HeaderButtonBackgroundHover}
                      borderColorHover={props.HeaderButtonBorderHover}
                      colorPressed={props.HeaderButtonColorPressed}
                      backgroundColorPressed={
                        props.HeaderButtonBackgroundPressed
                      }
                      borderColorPressed={props.HeaderButtonBorderPressed}
                    >
                      Get the App
                    </ButtonHeader>
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          {props.children}
        </FirstSectionBackground>
        <Push></Push>
      </Wrap>
      <Footer>
        <Container>
          <Row>
            <Col xs={12} md={4}>
              <FooterBrand>WomenWorks</FooterBrand>
              <TextFooterCol1>©2020 All Rights Reserved. WomenWorks is a trademark of CV Artha Moksa Tripitaka.</TextFooterCol1>
              <TextFooterCol1Margin>
                Contact us at{" "}
                {
                  <OutboundLink href="mailto:yours@womenworks.io">
                    yours@womenworks.io
                  </OutboundLink>
                }
              </TextFooterCol1Margin>
              <TextFooterCol1>
                <Link to={"/terms-of-use"}>Terms of Use & Privacy Policy</Link>{" "}
                | <Link to={"/faq/"}>FAQ</Link>
              </TextFooterCol1>
            </Col>
            <Col
              css={css`
                margin-top:34px;
                @media (max-width: 1199px) {
                  display: none;
                }
              `}
              xs={3}
            >
              <TextFooterCol2>
                <Link to={"/the-ladybosses/"}>The Ladybosses</Link>
              </TextFooterCol2>
              <TextFooterCol2>
                <Link to={"/he-for-she/"}>Our Male Allies</Link>
              </TextFooterCol2>
              <TextFooterCol2>
                <Link to={"/one-on-one-mentorship/"}>
                  1:1 Mentorship
                </Link>
              </TextFooterCol2>
              <TextFooterCol2>
                <Link to={"/masterclass/"}>Masterclass</Link>
              </TextFooterCol2>
              <TextFooterCol2Margin>
                <Link to={"/womenbiz-services/"}>WomenBiz Services</Link>
              </TextFooterCol2Margin>
              <TextFooterCol2Margin>
                <Link to={"/book-a-speaker/"}>Book a Speaker</Link>
              </TextFooterCol2Margin>
              <TextFooterCol2>
                <Link to={"/who-we-are/"}>Who We Are</Link>
              </TextFooterCol2>
              <TextFooterCol2MarginBottom>
                <Link to={"/app/"}>Get the App</Link>
              </TextFooterCol2MarginBottom>
            </Col>
            <Col xs={12} md={4} xl={3}>
              <TextFooterCol2Margin>
                Get informed of our latest updates:
              </TextFooterCol2Margin>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormControlSubmit
                  name="name"
                  type="text"
                  placeholder="Enter your name"
                  ref={register({})}
                />
                <FormControlSubmit
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  ref={register({
                    required: "Required",
                  })}
                />
                <ButtonSubmit variant="warning" type="submit">
                  Submit
                </ButtonSubmit>
              </Form>
            </Col>
            <Col xs={12} md={4} xl={2}>
              <TextFooterCol3Margin>Connect with us:</TextFooterCol3Margin>
              <SocmedRow>
                <Col xs={2} md={3} xl={4}>
                  <OutboundLink
                    href="https://www.instagram.com/womenworksid/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FASocmedButton icon={faInstagramSquare} />
                  </OutboundLink>
                </Col>
                <Col xs={2} md={3} xl={4}>
                  <OutboundLink
                    href="https://www.linkedin.com/company/womenworksid/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FASocmedButton icon={faLinkedin} />
                  </OutboundLink>
                </Col>
              </SocmedRow>
            </Col>
          </Row>
        </Container>
      </Footer>
      <Nudge/>
    </ThemeProvider>
  )
}
